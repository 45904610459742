import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
	name: "alertModal",
	initialState: {
		alert: {
			// Alertas
			show: false,
			icon: "",
			title: "",
			text: "",
			input: false,
			showConfirmButton: false,
			showConfirmButtonTwo: false,
			showCancelButton: false,
			confirmButtonText: "",
			confirmButtonTextTwo: "",
			CancelButtonText: "",
			onConfirm: () => {},
			onConfirmTwo: () => {},
			onClose: () => {},
		},
		loadAlert: false,
	},
	reducers: {
		refreshAlert: (state) => {
			state.alert = {
				show: false,
				icon: "",
				title: "",
				text: "",
				input: false,
				showConfirmButton: false,
				showConfirmButtonTwo: false,
				showCancelButton: false,
				confirmButtonText: "",
				confirmButtonTextTwo: "",
				CancelButtonText: "",
				onConfirm: () => {},
				onConfirmTwo: () => {},
				onClose: () => {},
			};
		},
		updatedAlert: (state, action) => {
			state.alert = action.payload;
		},
		updateLoadAlert: (state, action) => {
			state.loadAlert = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { refreshAlert, updatedAlert, updateLoadAlert } = alertSlice.actions;

export default alertSlice.reducer;
