import { Navigate } from "react-router-dom";
import { MODULOS } from "Globales"; //Globales
import { GetCookies } from "../cookies";
import { useSoftUIController } from "context";

/* -------------------------------------------------------- ||
||															||
||				  VALIDA EL ACCESO A LAS RUTAS				||
||															||
|| -------------------------------------------------------- */

const PrivateRoute = ({ children }) => {
	const [controller] = useSoftUIController();
	const { cookiesUser } = controller;
	const routes = GetCookies(MODULOS, cookiesUser); // Obtiene la cookies que contiene las rutas

	//Verifica que exista la cookie de rutas
	return routes ? (
		children
	) : (
		<Navigate
			to={{
				pathname: "/login",
			}}
		/>
	);
};

export default PrivateRoute;
