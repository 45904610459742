import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
	name: "modalActions",
	initialState: {
		modalActualizarDatos: {
			// Modal de actualizar datos
			estado: false,
			titulo: "",
			contenido: [],
		},
		modalPerfiles: {
			// Modal para cambiar de perfil
			estado: false,
			titulo: "",
			contenido: [],
		},
		modalAportantes: {
			// Modal para cambiar de aportante
			estado: false,
			titulo: "",
			contenido: [],
		},
		modalAportantesPagoIndividual: {
			// Modal para cambiar de aportante
			estado: false,
			titulo: "",
			contenido: [],
		},
		modalAsociarPerfil: false, // Modal para asociar un nuevo perfil
	},
	reducers: {
		refreshModal: (state) => {
			state.modalActualizarDatos = {
				estado: false,
				titulo: "",
				contenido: [],
			};
			state.modalPerfiles = {
				estado: false,
				titulo: "",
				contenido: [],
			};
			state.modalAportantes = {
				estado: false,
				titulo: "",
				contenido: [],
			};
			state.modalAportantesPagoIndividual = {
				estado: false,
				titulo: "",
				contenido: [],
				funcion: () => {},
			};
			state.modalAsociarPerfil = false;
		},
		updateActualizarDatos: (state, action) => {
			state.modalActualizarDatos = action.payload;
		},
		updatePerfiles: (state, action) => {
			state.modalPerfiles = action.payload;
		},
		updateAportantes: (state, action) => {
			state.modalAportantes = action.payload;
		},
		updateAsociarPerfil: (state, action) => {
			state.modalAsociarPerfil = action.payload;
		},
		selectAportantePagoInvididual: (state, action) => {
			state.modalAportantesPagoIndividual = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { refreshModal, updateActualizarDatos, updatePerfiles, updateAportantes, updateAsociarPerfil, selectAportantePagoInvididual } =
	modalSlice.actions;

export default modalSlice.reducer;
