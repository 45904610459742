import { useLocation, Navigate } from "react-router-dom";
import { MODULOS } from "Globales"; //Globales
import { desencriptarTexto } from "../encrypt";
import { GetCookies } from "../cookies";
import { useSoftUIController } from "context";

/* -------------------------------------------------------- ||
||															||
||	  VERIFICA TODOS LOS MODULOS CONTENIDOS EN LA COOKIE	||
||	  EN CASO DE QUE EL USUARIO SOLICITE ACCESO AL MODULO	||
|| Y ESTE NO SE ENCUENTRE EN LA COOKIE LO RETORNA AL INICIO	||
||															||
|| -------------------------------------------------------- */
const PrivateModules = ({ children }) => {
	const [controller] = useSoftUIController();
	const { cookiesUser } = controller;
	const ruta = GetCookies(MODULOS, cookiesUser);
	const location = useLocation();
	// Si es falso, no existen las rutas
	if (ruta === false) {
		return (
			<Navigate
				to={{
					pathname: "/login",
				}}
			/>
		);
	} else {
		const result = desencriptarTexto(ruta);
		const rutaActual = location.pathname.split("/")[2];
		var modulo = false;

		//Busca en las rutas a la cual el usuario quiere acceder
		result.map((item_array) => {
			if (item_array.includes(rutaActual)) {
				modulo = true;
			}
			return "";
		});
		return modulo === true ? (
			children
		) : (
			<Navigate
				to={{
					pathname: "/private/inicio",
				}}
			/>
		);
	}
};

export default PrivateModules;
