import { createSlice } from "@reduxjs/toolkit";

export const listSlice = createSlice({
	name: "listArray",
	initialState: {
		list_tipo_identificacion: [], // Tipo identificación
		list_consultas_ubicacion: [], // Ciudades y municipios
		list_cifras_interes: {}, // Cifras de interes
		list_consultas_usuarios: {}, // Listas de el modulo de usuarios
		list_consultas_clientes: {}, // Listas de el modulo de clientes
		list_consultas_asesor: {}, // Listas de el modulo de asesores
	},
	reducers: {
		refreshList: (state) => {
			state.list_tipo_identificacion = [];
			state.list_consultas_ubicacion = [];
			state.list_cifras_interes = {};
			state.list_consultas_usuarios = {};
			state.list_consultas_clientes = {};
			state.list_consultas_asesor = {};
		},
		updatedTipoIdentificacion: (state, action) => {
			state.list_tipo_identificacion = action.payload;
		},
		updatedConsultasUbicacion: (state, action) => {
			state.list_consultas_ubicacion = action.payload;
		},
		updatedCifrasInteres: (state, action) => {
			state.list_cifras_interes = action.payload;
		},
		updatedConsultasUsuarios: (state, action) => {
			state.list_consultas_usuarios = action.payload;
		},
		updatedConsultasClientes: (state, action) => {
			state.list_consultas_clientes = action.payload;
		},
		updatedConsultasAsesor: (state, action) => {
			state.list_consultas_asesor = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	refreshList,
	updatedTipoIdentificacion,
	updatedConsultasUbicacion,
	updatedCifrasInteres,
	updatedConsultasUsuarios,
	updatedConsultasClientes,
	updatedConsultasAsesor,
} = listSlice.actions;

export default listSlice.reducer;
