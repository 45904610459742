//Genera palabras de referencia que se usan en todos los modulos y las encripta
export const TOKEN = "token";
export const MODULOS = "modulos";
export const PERMISOS = "permisos";
export const DATOS_USUARIO = "datosUser";
export const DATOS_MODULOS = {
	1: [0],
	2: [0],
	3: [0],
	4: [0],
	5: [0],
	6: [1],
	7: [1],
	8: [1],
	9: [6],
	10: [4],
	11: [6],
	12: [6],
	13: [6],
	14: [4],
	15: [4],
	16: [4],
	17: [3],
	18: [3],
	19: [3],
	20: [5],
	21: [4],
	22: [2],
	23: [2],
	24: [2],
	25: [2],
	26: [3],
	27: [5],
	28: [4],
	29: [4],
	30: [2],
	31: [3],
	32: [3],
	33: [1],
	34: [6],
	35: [0],
	36: [35],
	37: [35],
	38: [35],
	39: [35],
	40: [35],
	41: [35],
	42: [5],
	43: [6],
	44: [4],
	45: [4],
	46: [0],
	47: [46],
	48: [48],
};
