/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo, useEffect } from "react";
import { useCookies } from "react-cookie";
import { RemoveCookies, SetCookies } from "components/cookies";
import { useLocation, useNavigate } from "react-router-dom";

// The Soft UI Dashboard PRO Material main context
const SoftUI = createContext(null);

// Setting custom name for the context which is visible on react dev tools
SoftUI.displayName = "SoftUIContext";

// Soft UI Dashboard React reducer
function reducer(state, action) {
	switch (action.type) {
		case "COOKIES_USER": {
			return { ...state, cookiesUser: action.value };
		}
		case "NEW_COOKIES": {
			return { ...state, newCookies: action.value };
		}
		case "DELETE_COOKIES": {
			return { ...state, deleteCookies: action.value };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

// Soft UI Dashboard React context provider
function SoftUIControllerProvider({ children }) {
	const initialState = {
		cookiesUser: {}, // Cookies del usuario
		newCookies: false, // Asignara nuevas cookies
		deleteCookies: false, // Eliminara las cookies
	};

	const [controller, dispatchContext] = useReducer(reducer, initialState);
	const { cookiesUser, newCookies, deleteCookies } = controller;
	const [cookies, setCookie, removeCookie] = useCookies(["name"]);
	const navigate = useNavigate();
	const location = useLocation().pathname;

	useEffect(() => {
		if (JSON.stringify(cookies) !== JSON.stringify(cookiesUser)) {
			setCookiesUser(dispatchContext, cookies);
		}
		// eslint-disable-next-line
	}, [cookies]);

	useEffect(() => {
		if (newCookies) {
			SetCookies(newCookies, setCookie);
			setNewCookies(dispatchContext, false);

			const dataCookies = Object.fromEntries(newCookies.map((item) => [item.name, item.text]));
			if (JSON.stringify(cookiesUser) !== JSON.stringify(dataCookies)) {
				setCookiesUser(dispatchContext, dataCookies);

				if (Object.keys(cookiesUser).length === 0) {
					// Si se esta logueando
					navigate("/private/inicio"); // Lo mueve al inicio
				} else if (location === "/" || location === "/login") {
					// Si tiene una sesión pero esta en el login
					navigate("/private/inicio"); // Lo mueve al inicio
				}
			}
		}
		// eslint-disable-next-line
	}, [newCookies]);

	useEffect(() => {
		if (deleteCookies) {
			RemoveCookies(deleteCookies, removeCookie);
			setDeleteCookies(dispatchContext, false);

			const newCookiesObject = {};
			for (const key in cookies) {
				if (!deleteCookies.some((item) => item.name === key)) {
					newCookiesObject[key] = cookies[key];
				}
			}

			setCookiesUser(dispatchContext, newCookiesObject);
		}
		// eslint-disable-next-line
	}, [deleteCookies]);

	const value = useMemo(() => [controller, dispatchContext], [controller, dispatchContext]);

	return <SoftUI.Provider value={value}>{children}</SoftUI.Provider>;
}

// Soft UI Dashboard React custom hook for using context
function useSoftUIController() {
	const context = useContext(SoftUI);

	if (!context) {
		throw new Error("useSoftUIController should be used inside the SoftUIControllerProvider.");
	}

	return context;
}

const setCookiesUser = (dispatch, value) => dispatch({ type: "COOKIES_USER", value });
const setNewCookies = (dispatch, value) => dispatch({ type: "NEW_COOKIES", value });
const setDeleteCookies = (dispatch, value) => dispatch({ type: "DELETE_COOKIES", value });

export { SoftUIControllerProvider, useSoftUIController, setCookiesUser, setNewCookies, setDeleteCookies };
