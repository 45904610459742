import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
	name: "sessionUser",
	initialState: {
		session: true, // Si esta logueado o no
	},
	reducers: {
		loggedIn: (state) => {
			state.session = true;
		},
		loggedOut: (state) => {
			state.session = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const { loggedIn, loggedOut } = sessionSlice.actions;

export default sessionSlice.reducer;
