import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./session";
import modalReducer from "./modal";
import alertReducer from "./alert";
import listReducer from "./list";

export default configureStore({
	reducer: {
		configSession: sessionReducer, // Sesión del usuario
		configModal: modalReducer, // Modales disponibles en todas las paginas
		configAlert: alertReducer, // Alertas
		configList: listReducer, // Listas disponibles de las paginas
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
