// import "css/loading.css";

/* -------------------------------------------------------- ||
||															||
||						PAGINA DE CARGA					||
||															||
|| -------------------------------------------------------- */

const Loading = () => {
	return <main className="loagind-page">loading</main>;
};

export default Loading;
