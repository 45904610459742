import CryptoJS from "crypto-js";
const secretKey = "s3Cr3etKey@2021*";

// Encripta los textos recibidos
export function encriptarTexto(element) {
	const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(element), secretKey).toString();
	return ciphertext;
}

// Desencripta los textos recibidos
export function desencriptarTexto(element) {
	const bytes = CryptoJS.AES.decrypt(element, secretKey);
	const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	return decryptedData;
}
