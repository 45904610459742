import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "context/authContext";
import { SoftUIControllerProvider } from "context";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux/configureStore";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.css";

const root = createRoot(document.getElementById("root"));
root.render(
	<StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<SoftUIControllerProvider>
					<AuthProvider>
						<App />
					</AuthProvider>
				</SoftUIControllerProvider>
			</BrowserRouter>
		</Provider>
	</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
