import { createContext, useState, useEffect } from "react";
import { DATOS_USUARIO } from "Globales";
import { desencriptarTexto } from "components/encrypt";
import { GetCookies } from "components/cookies";
import { loggedIn, loggedOut } from "redux/session";
import { useDispatch, useSelector } from "react-redux";
import { useSoftUIController } from "context";

/* -------------------------------------------------------- ||
||															||
||				CREA LOS NECESARIO PARA UNA SESION			||
||		  TAMBIEN PERMITE QUE CONTINUE CON LAS PAGINAS		||
||															||
|| -------------------------------------------------------- */

const AuthContext = createContext();
const initialAuth = null;

const AuthProvider = ({ children }) => {
	const [controller] = useSoftUIController();
	const { cookiesUser } = controller;
	const { session } = useSelector((state) => state.configSession);
	const [auth, setAuth] = useState(initialAuth);
	const dispatch = useDispatch();

	useEffect(() => {
		addDataUser();
		// eslint-disable-next-line
	}, [cookiesUser]);

	const addDataUser = (data) => {
		if (data === "salir") {
			handleAuth(null, null, null, null, null);
		} else if (data) {
			const resultUser = desencriptarTexto(data);
			resultUser[4] = JSON.parse(resultUser[4]);
			handleAuth(resultUser[0], resultUser[1], resultUser[2], resultUser[3], resultUser[4]);
		} else {
			const user = GetCookies(DATOS_USUARIO, cookiesUser);
			if (user) {
				if (!session) {
					dispatch(loggedIn());
				}

				const resultUser = desencriptarTexto(user);
				resultUser[4] = JSON.parse(resultUser[4]);
				handleAuth(resultUser[0], resultUser[1], resultUser[2], resultUser[3], resultUser[4]);
			} else {
				dispatch(loggedOut());
			}
		}
	};

	const handleAuth = (id, nombres, apellidos, rol, aportante) => {
		if (id && nombres && apellidos && rol) {
			setAuth({
				id: id,
				nombres: nombres,
				apellidos: apellidos,
				id_rol: rol,
				aportante: aportante,
			});
		} else {
			setAuth(null);
		}
	};

	const data = { auth, addDataUser };

	return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
export default AuthContext;

/*

const authContext = createContext();




export function useAuth() {
	return useContext(authContext);
}

/ ** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 * /
export default function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return (
		<authContext.Provider value={auth}>
			{children}
		</authContext.Provider>
	);
}

function useProvideAuth() {
	const [user, setUser] = useState(null);

	const signin = cb => {
		return fakeAuth.signin(() => {
			setUser("user");
			cb();
		});
	};

	const signout = cb => {
		return fakeAuth.signout(() => {
			setUser(null);
			cb();
		});
	};

	return {
		user,
		signin,
		signout
	};
}

const fakeAuth = {
	isAuthenticated: false,
	signin(cb) {
		fakeAuth.isAuthenticated = true;
		setTimeout(cb, 100); // fake async
	},
	signout(cb) {
		fakeAuth.isAuthenticated = false;
		setTimeout(cb, 100);
	}
};
*/
