/* -------------------------------------------------------- ||
||															||
||		   OBTIENE LAS COOKIES EN STORE DEL NAVEGADOR		||
||															||
|| -------------------------------------------------------- */

export const SetCookies = (cookies, setCookie) => {
	cookies.forEach((element) =>
		setCookie(element.name, element.text, {
			path: "/",
			maxAge: element.maxAge,
			sameSite: element.sameSite,
			domain: element.domain,
			// secure: process.env.NODE_ENV === "production",
			// httpOnly: process.env.NODE_ENV === "production",
		})
	);
};

export const GetCookies = (nombreCookie, cookies) => {
	if (typeof nombreCookie === "string") {
		return cookies[nombreCookie] ? cookies[nombreCookie] : false;
	} else {
		return false;
	}
};

export const RemoveCookies = (content, removeCookie) => {
	content.forEach((element) => removeCookie(element.name, { path: "/", domain: process.env.REACT_APP_DOMAIN }));
};
